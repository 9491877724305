.link-list[data-v-42afcfc9] {
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  min-height: 32px;
  border: 1px solid #DCDFE6;
}
.link-list .link-item[data-v-42afcfc9] {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.link-list .link-item .text[data-v-42afcfc9] {
  color: #409eff;
  text-decoration: underline;
  -webkit-text-decoration-color: #409eff;
          text-decoration-color: #409eff;
  margin-right: 10px;
}
.link-list .link-item .el-icon-remove[data-v-42afcfc9] {
  color: red;
  cursor: pointer;
}
.add-form[data-v-42afcfc9] {
  position: absolute;
  top: 0;
  background-color: rgb(240, 242, 245);
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: auto;
}
.add-form .block[data-v-42afcfc9] {
  padding: 20px;
  background: #fff;
}
[data-v-42afcfc9].dangerCheckList {
  width: 70%;
}
[data-v-42afcfc9].dangerCheckList .el-textarea {
  display: inline-block;
  margin-left: 10px;
}
.flex-checkList[data-v-42afcfc9] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.el-form-title[data-v-42afcfc9] {
  padding: 0 0 20px 0;
}
.el-col-checkList[data-v-42afcfc9] {
  margin-bottom: 10px !important;
}
.protection-checkList[data-v-42afcfc9] {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
}
.protection-checkList .title[data-v-42afcfc9] {
  width: 60px;
  text-align: right;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 14px;
  margin-right: 20px;
}
.protection-checkList .el-checkbox-group[data-v-42afcfc9] {
  width: calc(100% - 60px);
}
.protection-checkList .el-textarea[data-v-42afcfc9] {
  display: inline-block;
  margin-left: 10px;
}
.waste-checkList[data-v-42afcfc9] {
  margin: 10px 0;
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.waste-checkList .title[data-v-42afcfc9] {
  width: 60px;
  text-align: right;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 14px;
  margin-right: 20px;
}
.waste-checkList .el-checkbox-group[data-v-42afcfc9] {
  width: calc(100% - 60px);
}
[data-v-42afcfc9].protect-checkList {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
}
[data-v-42afcfc9].protect-checkList .title {
  width: 100px;
  text-align: right;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 20px;
}
[data-v-42afcfc9].protect-checkList .el-checkbox-group {
  width: calc(100% - 100px);
}
[data-v-42afcfc9].protect-checkList .el-checkbox-group .el-textarea {
  display: inline-block;
  margin-left: 10px;
}
.layout-title[data-v-42afcfc9] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 0;
}
.top-header[data-v-42afcfc9] {
  padding: 0 16px;
  background: #fff;
  top: 0;
  width: -webkit-fill-available;
  left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e5e6eb;
  height: 40px;
}
.top-header[data-v-42afcfc9] .head-layout {
  border-bottom: 0;
  height: auto;
}
.sticky[data-v-42afcfc9] {
  position: sticky;
  top: 0;
  z-index: 100;
}