.riskIdentification[data-v-59fb3604] {
  background-color: #ffffff;
}
.riskIdentification .common_tree_handle .el-tree[data-v-59fb3604] {
  height: calc(100vh - 278px) !important;
}
.riskIdentification .table-box[data-v-59fb3604] {
  width: calc(100% - 280px);
}
[data-v-59fb3604] .introduceTable .avue-crud .el-table {
  height: calc(100vh - 330px) !important;
  max-height: calc(100vh - 330px) !important;
}
.introduceTree[data-v-59fb3604] .common_tree_handle .el-tree {
  height: calc(100vh - 278px) !important;
}
[data-v-59fb3604] .el-tabs__header {
  margin-bottom: 0;
}
[data-v-59fb3604] .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}
[data-v-59fb3604] .el-tabs__content {
  padding: 0 !important;
}